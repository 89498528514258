import tw, { styled } from "twin.macro"

export const DetailsWrapper = tw.div`
  grid grid-cols-1 md:grid-cols-2 gap-y-3-2 md:gap-y-5
`

export const DetailTitle = styled.h3<TitleProps>`
  ${tw`max-w-25-7 text-16 leading-1.2 font-bold uppercase mb-1-6 md:mb-2-4 tracking-10`}
`

export const DetailLine = tw.p`
  block text-14 leading-1.78
`

type TitleProps = {
  shorter: "true" | "false"
}
