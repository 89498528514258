import React from "react"

import { useRoutes } from "../../../hooks/useRoutes"

export const withLinks = Component => ({
  name = "links",
  title,
  links: originalLinks,
}) => {
  const { linkResolver } = useRoutes()

  const links = originalLinks?.map(link => ({
    ...linkResolver(link),
    key: link._key,
  }))

  Component.displayName = name
  return <Component title={title} links={links} />
}
