import tw, { styled } from "twin.macro"
import { StyledContainer } from "../Styled/Container"

export const Outer = styled(StyledContainer)`
  ${tw`pt-3-2 md:pt-8`}
`

export const Title = tw.h1`
  text-28 md:text-35 leading-1.28 tracking-10 font-bold  uppercase text-center mb-4 md:mb-8
`

export const Inner = tw.div`
  max-w-107 mx-auto border-b border-navy border-opacity-20
`

export const Section = tw.div`
  flex flex-col md:flex-row py-3-2 md:py-8 md:justify-between border-t border-navy border-opacity-25
`

export const Subtitle = tw.h3`
  w-full md:w-1/3 max-w-28 uppercase text-20 md:text-25 leading-1.5 md:leading-1.4 tracking-5 font-bold mb-2-4
`

export const Content = tw.div`
  block w-full md:w-3/5 max-w-63
`
