import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useForm } from "../../../hooks/useForm"

export const withForm = Component => ({
  name = "Form",
  title,
  description,
}) => {
  const { contact } = useStaticQuery(graphql`
    query SANITY_PAGE_CONTACT {
      contact: sanityPageContact {
        additionalSubmitText
        additionalSubmittingText
        additionalSuccessTitle
        additionalSuccessText
        additionalLabelFirstName
        additionalLabelLastName
        additionalLabelEmail
        additionalLabelType
        additionalLabelMessage
        additionalPlaceholderType
      }
    }
  `)

  const {
    additionalSubmitText,
    additionalSubmittingText,
    additionalSuccessTitle,
    additionalSuccessText,
    additionalLabelFirstName,
    additionalLabelLastName,
    additionalLabelEmail,
    additionalLabelType,
    additionalLabelMessage,
    additionalPlaceholderType,
  } = contact || {}

  const {
    getForm,
    submitForm,
    data,
    setData,
    loading,
    errors,
    success,
  } = useForm()

  const form = getForm("contact")

  const handleSubmit = async event => {
    event.preventDefault()
    await submitForm(form, data)
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const options = Object.values(form?.types || {})?.map(type => ({
    label: type.title,
    value: type.title,
  }))

  Component.displayName = name
  return (
    <Component
      title={title}
      description={description}
      data={data}
      form={form}
      options={options}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      errors={errors}
      success={success}
      additionalSubmitText={additionalSubmitText}
      additionalSubmittingText={additionalSubmittingText}
      additionalSuccessTitle={additionalSuccessTitle}
      additionalSuccessText={additionalSuccessText}
      additionalLabelFirstName={additionalLabelFirstName}
      additionalLabelLastName={additionalLabelLastName}
      additionalLabelEmail={additionalLabelEmail}
      additionalLabelType={additionalLabelType}
      additionalLabelMessage={additionalLabelMessage}
      additionalPlaceholderType={additionalPlaceholderType}
    />
  )
}
