import { useState, useCallback, useRef } from "react"

import { useForms } from "./useForms"
import { useFunctions } from "./useFunctions"
import { useLocation } from "./useLocation"

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  type: "",
  message: "",
}

export const useForm = () => {
  const { forms } = useForms()
  const { userData } = useLocation()
  const { formSubmission } = useFunctions()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState(INITIAL_STATE)
  const [success, setSuccess] = useState(false)

  const submitForm = useCallback(
    async (form, data) => {
      setLoading(true)
      setErrors([])

      const { ipData, userAgent } = await userData()
      const { status, body } = await formSubmission(
        form,
        {
          name: `${data.firstName} ${data.lastName}`,
          email: data.email,
          message: data.message,
          type: data.type,
        },
        ipData,
        userAgent
      )

      if (status === "error") setErrors([body])
      if (status === "success") {
        setData(INITIAL_STATE)
        setSuccess(true)
      }

      setLoading(false)
    },
    [setLoading, setErrors, setData, userData, formSubmission]
  )

  const getForm = slug =>
    forms?.edges
      ?.filter(({ node }) => node?.slug?.current === slug)
      .map(({ node }) => ({
        ...node,
        slug: node?.slug?.current,
        types: Object.assign({}, ...node?.types.map(type => ({ [type?.title?.toLowerCase()]: { ...type } }))),
      }))[0] || false

  return { getForm, submitForm, data, setData, loading, errors, success }
}

export const useFocus = () => {
  const ref = useRef(null)
  const setFocus = () => {
    ref.current && ref.current.focus()
  }

  return [ref, setFocus]
}
