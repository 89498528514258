import React from "react"

import { withLinks } from "./withLinks"
import { LinksWrapper } from "./LinksStyles"
import { UnderlinedButton } from "../../Styled/Button"
import { Link } from "../../Link/Link"
import { Section, Subtitle, Content } from "../ContactStyles"

export const Links = withLinks(({ title, links }) => (
  <Section>
    <Subtitle>{title}</Subtitle>
    <Content>
      <LinksWrapper>
        {links?.map(link => (
          <div key={link.key}>
            <UnderlinedButton>
              <Link
                title={link?.title}
                url={link?.url}
                external={link?.external}
                isExternal={link?.isExternal}
              />
            </UnderlinedButton>
          </div>
        ))}
      </LinksWrapper>
    </Content>
  </Section>
))
