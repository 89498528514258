import React from "react"

import { useImage } from "../../hooks/useImage"
import { useRoutes } from "../../hooks/useRoutes"

export const withContact =
  Component =>
  ({ name = "Contact", page }) => {
    const { getGatsbyImage } = useImage()
    const { linkResolver } = useRoutes()

    const {
      title,
      image: originalImage,
      links,
      faqs,
      faqsLink,
      contactFormDescription,
      contacts,
      footerContent,
      additionalLinksText,
      additionalFaqsText,
      additionalEnquiresText,
      additionalLocationsText,
    } = page || {}

    const image = getGatsbyImage(originalImage, { maxWidth: 1440 })
    const link = linkResolver(faqsLink)

    Component.displayName = name
    return (
      <Component
        title={title}
        image={image}
        links={links}
        faqs={faqs}
        link={link}
        contactFormDescription={contactFormDescription}
        contacts={contacts}
        footerContent={footerContent}
        additionalLinksText={additionalLinksText}
        additionalFaqsText={additionalFaqsText}
        additionalEnquiresText={additionalEnquiresText}
        additionalLocationsText={additionalLocationsText}
      />
    )
  }
