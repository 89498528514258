import React from "react"

import { withDetails } from "./withDetails"
import { DetailsWrapper, DetailTitle } from "./DetailsStyles"
import { Section, Subtitle, Content } from "../ContactStyles"
import { RichText } from "../../RichText/RichText"

export const Details = withDetails(({ title, contacts }) => (
  <Section>
    <Subtitle>{title}</Subtitle>
    <Content>
      <DetailsWrapper>
        {contacts?.map(({ _key, title, content }) => (
          <div key={_key}>
            <DetailTitle>{title}</DetailTitle>
            <RichText>{content}</RichText>
          </div>
        ))}
      </DetailsWrapper>
    </Content>
  </Section>
))
