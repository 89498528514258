import { useStaticQuery, graphql } from "gatsby"

export const useForms = () =>
  useStaticQuery(graphql`
    query FORMS_SANITY_SETTINGS {
      forms: allSanitySettingForms {
        edges {
          node {
            id: _id
            title
            slug {
              current
            }
            subject
            types {
              title
              recipients
            }
          }
        }
      }
    }
  `)
