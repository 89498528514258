import React from "react"

import { withFaqs } from "./withFaqs"
import { Section, Content } from "../ContactStyles"
import { Accordion } from "../../Accordion/Accordion"
import { RichText } from "../../RichText/RichText"
import { UnderlinedButton } from "../../Styled/Button"
import { Link } from "../../Link/Link"
import { Title, LinkWrapper, TitleWrapper } from "./FaqsStyles"

export const Faqs = withFaqs(({ title, faqs, link }) => (
  <Section>
    <TitleWrapper>
      <Title>{title}</Title>
      <LinkWrapper>
        <UnderlinedButton size="primary">
          <Link
            title={link?.title}
            url={link?.url}
            external={link?.external}
            isExternal={link?.isExternal}
          />
        </UnderlinedButton>
      </LinkWrapper>
    </TitleWrapper>
    <Content>
      {faqs?.map(faq => (
        <Accordion key={faq._key} layout={"faq"} title={faq.question}>
          <RichText spacing={"default"}>{faq.answer}</RichText>
        </Accordion>
      ))}
    </Content>
  </Section>
))
