import React from "react"

import { withForm } from "./withForm"
import {
  ContactForm,
  Description,
  SuccessWrapper,
  SuccessTitle,
  SuccessMessage,
  ErrorMessage,
  NameWrapper,
} from "./FormStyles"
import { Section, Subtitle, Content } from "../ContactStyles"
import { StyledTextArea, StyledLabel } from "../../Styled/Form"
import { TextField } from "../../TextField/TextField"
import { StyledButton } from "../../Styled/Button"
import { Select } from "../../Select/Select"

export const Form = withForm(
  ({
    title,
    description,
    data,
    options,
    handleChange,
    handleSubmit,
    loading,
    errors,
    success,
    additionalSubmitText,
    additionalSubmittingText,
    additionalSuccessTitle,
    additionalSuccessText,
    additionalLabelFirstName,
    additionalLabelLastName,
    additionalLabelEmail,
    additionalLabelType,
    additionalLabelMessage,
    additionalPlaceholderType,
  }) => (
    <Section>
      <Subtitle>{title}</Subtitle>
      <Content>
        {!success ? (
          <ContactForm onSubmit={handleSubmit}>
            <Description>{description}</Description>
            <NameWrapper>
              <TextField
                name="firstName"
                type="text"
                value={data?.firstName}
                onChange={handleChange}
                label={additionalLabelFirstName}
                required
                spacing
              />
              <TextField
                name="lastName"
                type="text"
                value={data?.lastName}
                onChange={handleChange}
                label={additionalLabelLastName}
                required
                spacing
              />
            </NameWrapper>
            <TextField
              name="email"
              type="email"
              value={data?.email}
              onChange={handleChange}
              label={additionalLabelEmail}
              required
              spacing
            />
            <Select
              layout={"form"}
              name={"type"}
              options={options}
              value={data?.type}
              onChange={handleChange}
              label={additionalLabelType}
              defaultOption={additionalPlaceholderType}
              spacing
            />
            <StyledLabel>{additionalLabelMessage}</StyledLabel>
            <StyledTextArea
              name="message"
              value={data?.message}
              onChange={handleChange}
              required
              spacing
            />

            <StyledButton
              type={`submit`}
              disabled={loading}
              size={"primary"}
              wide
            >
              {loading ? additionalSubmittingText : additionalSubmitText}
            </StyledButton>
            {errors?.length > 0 ? (
              <>
                {errors?.map(error => (
                  <ErrorMessage key={error}>{error}</ErrorMessage>
                ))}
              </>
            ) : null}
          </ContactForm>
        ) : (
          <SuccessWrapper>
            <SuccessTitle>{additionalSuccessTitle}</SuccessTitle>
            <SuccessMessage>{additionalSuccessText}</SuccessMessage>
          </SuccessWrapper>
        )}
      </Content>
    </Section>
  ),
)
