import React from "react"

import { withContact } from "./withContact"
import { Form } from "./Form/Form"
import { Outer, Title, Inner } from "./ContactStyles"
import { Links } from "./Links/Links"
import { Faqs } from "./Faqs/Faqs"
import { Details } from "./Details/Details"
import { Sections } from "../Sections/Sections"

export const Contact = withContact(
  ({
    title,
    links,
    faqs,
    link,
    contactFormDescription,
    contacts,
    footerContent,
    additionalLinksText,
    additionalFaqsText,
    additionalEnquiresText,
    additionalLocationsText,
  }) => (
    <>
      <Outer>
        <Title>{title}</Title>
        <Inner>
          <Links title={additionalLinksText} links={links} />
          <Faqs title={additionalFaqsText} faqs={faqs} link={link} />
          <Form
            title={additionalEnquiresText}
            description={contactFormDescription}
          />
          <Details title={additionalLocationsText} contacts={contacts} />
        </Inner>
      </Outer>
      <Sections page={{ content: footerContent }} />
    </>
  ),
)
