import React from "react"
import { graphql } from "gatsby"

import { Contact as Page } from "../components/Contact/Contact"

import { useHotjar } from '../hooks/useHotjar'

export const query = graphql`
  query {
    page: sanityPageContact {
      title
      links: _rawLinks(resolveReferences: { maxDepth: 2 })
      faqs: _rawFaqs(resolveReferences: { maxDepth: 2 })
      faqsLink: _rawFaqsLink(resolveReferences: { maxDepth: 2 })
      contactFormDescription
      contacts: _rawContacts(resolveReferences: { maxDepth: 2 })
      footerContent: _rawFooterContent(resolveReferences: { maxDepth: 8 })
      additionalLinksText
      additionalFaqsText
      additionalEnquiresText
      additionalLocationsText
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("contact_page")

  return <Page {...props} {...data} />
}
export default Component
