import tw from "twin.macro"

export const ContactForm = tw.form`
  
`

export const Description = tw.p`
  text-14 leading-1.78 mb-3-2
`

export const SuccessWrapper = tw.div`
  
`

export const SuccessTitle = tw.h6`
  uppercase text-20 leading-1.25 tracking-2 font-bold mb-3-2
`

export const SuccessMessage = tw.p`
  text-14 leading-1.78
`

export const ErrorMessage = tw.p`
  text-14 leading-1.78 mt-2-4
`

export const NameWrapper = tw.div`
  grid grid-cols-2 gap-x-0-8 md:gap-x-1-6
`
export const FlexibleWrapper = tw.div`
  md:flex md:space-x-0-8 md:space-x-1-6
`
export const RowWrapper = tw.div`
  grid grid-cols-1 gap-x-0-8 md:gap-x-1-6
`
