import tw from "twin.macro"

export const TitleWrapper = tw.div`
  flex flex-col
`

export const LinkWrapper = tw.div`
  mb-2-4
`

export const Title = tw.h3`
  max-w-28 uppercase text-20 md:text-25 leading-1.5 md:leading-1.4 tracking-5 font-bold mb-2-4
`
